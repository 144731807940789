import { Link } from 'react-router-dom';
import './creators.css';

const Skillsharer = () => {
    const twitterProfileImageUrl = 'https://pbs.twimg.com/profile_images/1702741769769693184/K_ISxJnQ_400x400.jpg'; // Replace with actual profile image URL

    return (
        <div className="portfolio-container">
             <div className="profile-image-container">
                 <img
                     src={twitterProfileImageUrl}
                     alt="Skillsharer Twitter Profile"
                     className="profile-image"
                 />
            </div>
            <h1 className="authors-container-title">Skillsharer</h1>
            <p className="authors-container-text">
                <strong>Platform:</strong>
                <a href="https://x.com/_skillsharer_" rel="noreferrer" target="_blank">X</a>,
                <a href="https://medium.com/@skillsharer" rel="noreferrer" target="_blank">Medium</a>,
                <a href="https://github.com/skillsharer" rel="noreferrer" target="_blank">GitHub</a>
                <br />
                <strong>Focus:</strong> Education, Machine Learning, Python and C++ programming, Data Analysis, API usage and Automation, Web Development, Blockchain Development.
            </p>
            <h2 className="authors-container-title">Content Focus</h2>
            <p className="authors-container-text">
                <strong>Educational Content:</strong> Skillsharer is dedicated to sharing knowledge, particularly in technology and programming. Articles and posts span a wide array of topics, from basic programming to advanced concepts in machine learning, data analysis, and web development.
            </p>
            <p className="authors-container-text">
                <strong>Software Development:</strong> Drawing from a background in software engineering, Skillsharer applies experience in cloud systems, deep neural network optimization, real and synthetic data generation, and algorithm development. This background ensures content is practical and relevant.
            </p>
            <p className="authors-container-text">
                <strong>Programming:</strong> A significant portion of the work focuses on Python and C++ programming, covering everything from basic scripts to complex automation projects and web development. Step-by-step guides make learning accessible to various levels.
            </p>
            <p className="authors-container-text">
                <strong>API and Automation:</strong> Skillsharer explores API interactions and automation, demonstrating how to harness these tools for efficient task management and data analysis with practical, real-world examples.
            </p>
            <p className="authors-container-text">
                <strong>Web Development:</strong> Leveraging experience in building websites, including NFT platforms, Skillsharer provides guides and insights into web development using tools like Flask and other related technologies.
            </p>
            <p className="authors-container-text">
                <strong>Blockchain Development:</strong> Expertise in blockchain development includes programming smart contracts on Ethereum, building bots on the Solana blockchain, and several years of knowledge in the field.
            </p>
            <p className="authors-container-text">
                <strong>Community Building:</strong> By offering educational content, Skillsharer engages with an audience interested in tech education, including programming, machine learning, data analysis, web development, and blockchain. This attracts students, hobbyists, and professionals seeking to broaden their skills.
            </p>
            <p className="authors-container-text">
                <strong>Support for Learners:</strong> Skillsharer supports followers by addressing questions, clarifying concepts, and providing resources, which is valuable for individuals learning independently.
            </p>
            <h2 className="authors-container-title">Professional Experience</h2>
            <p className="authors-container-text">
                <strong>Diverse Background:</strong> With 10 years in the tech industry, Skillsharer brings a wealth of knowledge in AI, machine learning, and software development.
                <br />
                <strong>Industry Experience:</strong> Worked on various AI/Machine Learning projects including real and synthetic data generation, computer vision, cloud systems optimization, and autonomous vehicles.
                <br />
                <strong>Product Ownership:</strong> Experience as a Developer and Product Owner.
                <br />
                <strong>Mentorship:</strong> Involved in mentoring and training roles.
                <br />
                <strong>Project Contributions:</strong> Contributed to deep neural network development and optimization, and the development of various algorithms and automated systems.
            </p>
            <h2 className="authors-container-title">Key Projects & Contributions</h2>
            <p className="authors-container-text">
                <ul>
                    <li><strong>AI-Powered YouTube Summarizer:</strong> Developed an LLM-based tool to summarize YouTube videos, showcasing skills in natural language processing and large language model implementation.</li>
                    <li><strong>Automation Bots:</strong> Created Telegram and Twitter bots, demonstrating proficiency in API integration and task automation across different platforms.</li>
                    <li><strong>Daily Good News Site:</strong> Launched and maintains <a href="https://www.dailygoodnews.co.uk" target="_blank" rel="noopener noreferrer">dailygoodnews.co.uk</a>, a platform sharing positive news, highlighting content creation and website management abilities.</li>
                    <li><strong>Academic Summarization Service:</strong> Automates the summarization of Arxiv articles and publishes them on X, showcasing skills in automated data aggregation, API usage, and content delivery.</li>
                    <li><strong>NFT Art Projects:</strong> Created generative art NFT projects on fxhash, with a portfolio viewable at <a href="https://www.fxhash.xyz/u/skillsharer" target="_blank" rel="noopener noreferrer">fxhash.xyz/u/skillsharer</a>, demonstrating creative coding and blockchain engagement.</li>
                </ul>
            </p>
            <Link to="/" className="back-link">Back to Home</Link>
        </div>
    );
};

export default Skillsharer;