import { Link } from 'react-router-dom';
import './creators.css';

const Cypherdoc = () => {
    const twitterProfileImageUrl = 'https://pbs.twimg.com/profile_images/1864094559279398912/AN0IdmW__400x400.jpg';  // Replace with actual profile image URL

    return (
        <div className="portfolio-container">
            <div className="profile-image-container">
                <img
                    src={twitterProfileImageUrl}
                    alt="Cypherdoc Twitter Profile"
                    className="profile-image"
                />
            </div>
            <h1 className="authors-container-title">Cypherdoc</h1>
            <p className="authors-container-text">
                <strong>Platform:</strong>
                <a href="https://x.com/cypherdoc1" rel="noreferrer" target="_blank">X</a>,
                <a href="https://github.com/drgoodnight" rel="noreferrer" target="_blank">GitHub</a>
                <br />
                <strong>Focus:</strong> Bitcoin, Ethereum, Monero, blockchain technology, financial privacy, and decentralized finance (DeFi).
            </p>
            <h2 className="authors-container-title">Content Focus</h2>
            <p className="authors-container-text">
                <strong>Cryptocurrency Advocacy:</strong> Cypherdoc actively champions cryptocurrencies, highlighting the unique attributes of Bitcoin, Ethereum and Monero. He explores Bitcoin's potential through Ordinals and Runes, blending cultural and financial innovation, and promotes Monero for its strong privacy and financial sovereignty features.
            </p>
            <p className="authors-container-text">
                <strong>Blockchain Development:</strong> With an eye toward the future, Cypherdoc examines ways to enhance blockchain agents beyond basic wallet trades and DEX swaps, striving for more sophisticated interactions with smart contracts.
            </p>
            <h2 className="authors-container-title">Engagement Style</h2>
            <p className="authors-container-text">
                <strong>Educational Insights:</strong> His posts often provide educational insights, demonstrating how different cryptocurrencies can complement each other without competition, specifically highlighting the unique strengths of both Bitcoin and Monero.
            </p>
            <p className="authors-container-text">
                <strong>Thought-Provoking Leadership:</strong>  Cypherdoc positions himself as a thought leader within the crypto community, discussing advanced concepts that could shape the trajectory of blockchain technology.
            </p>
            <h2 className="authors-container-title">Audience Interaction</h2>
            <p className="authors-container-text">
                <strong>Community Engagement:</strong> Through sharing knowledge of blockchain and cryptocurrency, Cypherdoc engages with a community interested in technology, finance, and the core philosophies of digital currencies.
            </p>
            <p className="authors-container-text">
                <strong>Discussions and Debates:</strong> Cypherdoc’s content ignites discussions about the practical applications and ethical implications of blockchain technologies, especially in areas like privacy and financial freedom.
            </p>
            <h2 className="authors-container-title">Key Contributions</h2>
            <p className="authors-container-text">
                <ul>
                    <li>Advocacy for transactional privacy through Monero.</li>
                    <li>Exploration of the broader use of Bitcoin through Ordinals and Runes.</li>
                    <li>Examination of complex blockchain interactions with smart contracts.</li>
                    <li>Exploration of the complementary roles of Bitcoin and Monero within the crypto ecosystem.</li>
                </ul>
            </p>
           <h2 className="authors-container-title">A Glimpse into the Past: Cypherdoc's First Game</h2>
              <div className='game-container'>
                  <p className='game-description'>
                      Step back in time to see where Cypherdoc's coding journey began! About two years ago, he created his very first game using Scratch. Take a shot at his balloon-flying, sloth-catching adventure, while dodging lightning and an evil bird!  It's been quite a ride, and his programming skills have skyrocketed since then.
                  </p>
                <iframe
                    title="Cypherdoc's Sloth Game"
                    src="https://scratch.mit.edu/projects/722364541/embed"
                    allowtransparency="true"
                    className="game-iframe"
                 />
            </div>
            <Link to="/" className="back-link">Back to Home</Link>
        </div>
    );
};

export default Cypherdoc;