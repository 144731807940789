import './Footer.css';
import twitter from '../assets/twitter.svg';
import discord from '../assets/discord.svg';

function Footer(props) {
    return (
        <footer className='footer'>
            <p>
                SMART CONTRACT ADDRESS:&nbsp;
                <br />
                <span>
                    <a className='contract-link' href={`https://etherscan.io/address/${props.address}`} target='_blank' rel='noreferrer'>
                        {props.address}
                    </a>
                </span>
            </p>
            <div className='footer-social-media-links'>
                <div>
                <a href='https://x.com/cblz_ai' target='_blank' rel='noreferrer'>
                    <img src={twitter} alt="Twitter" className="social-icon"/>
                </a>
                <a href='https://discord.gg/aJ9uXP9gyB' target='_blank' rel='noreferrer'>
                    <img src={discord} alt="Discord" className="social-icon"/>
                </a>
                </div>
            </div>
            <p>© {new Date().getFullYear()} CryptoBalloonerz. All rights reserved.</p>       
        </footer>
    )
}

export default Footer;