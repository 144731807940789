import './Header.css';

function Header(props) {
    return (
        <header>
            <h1 className="heading gradient-text">
                <a href={props.homeLink || "#"}>
                    CryptoBalloonerz
                </a>
            </h1>
            <h2 className='subheading'>
              {`3141 hot Ar(weave) balloons unleashed on the Ethereum network by `}
              <span><a href='https://x.com/_skillsharer_' target='_blank' rel='noreferrer'>skillsharer</a></span>
              {` and `}
              <span><a href='https://x.com/cypherdoc1' target='_blank' rel='noreferrer'>cypherdoc</a></span>.
           </h2>
            <div>
                <button className='os-button'>
                    <a href={props.opensea} target='_blank' rel='noreferrer'>
                        View Collection on Opensea
                    </a>
                </button>
            </div>
        </header>
    )
}

export default Header;